<template>
  <div :class="{ contain: true, mobile: isMobile }" >
    <div
      class="d-flex flex-column justify-center align-center banner"
      style="width: 100%"
    >
      <!-- <img
        v-if="uploadFileUrl"
        class="clo"
        width="31px"
        height="31px"
        @click.stop="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      /> -->
      <FIleInput accept=".jpg,.png,.gif,.jpeg" @change="change" :key="fileInputKey">
        <template>
          <v-btn dark  width="220px" height="40px" rounded class="bannerBtn" :class="{'sub-dis': showMask}"
            >{{ $t("myInfoEditProfileBanner") }}
          </v-btn>
        </template>
      </FIleInput>
      <v-img
        class="bannerurl"
        v-if="uploadFileUrl"
        :src="uploadFileUrl"
        width="100%"
        height="100%"
        cover
      ></v-img>
    </div>

    <div class="image">
      <v-avatar class="ava" size="191">
        <v-img
          v-if="typeof uploadAvatar == 'undefined'"
          src="@/assets/default.png"
          alt=""
          width="100%"
          height="100%"
        />
        <v-img v-else :src="uploadAvatar" alt="" width="100%" height="100%" />
      </v-avatar>
      <div class="edit_img mt-lg-4 mt-8 mb-lg-8 mb-6">
        <FIleInput accept=".jpg,.png,.gif,.jpeg" @change="avaChange" :key="fileInputKey" >
          <div class="edit_image" :class="{'sub-dis1': showMask1}">{{ $t("myInfoEditImage") }}</div>
        </FIleInput>
      </div>
    </div>
    <div class="form">
      <div class="copy_link ml-lg-12 mb-10 d-flex flex-row">
        <span v-if="!isMobile">{{ did }}</span>
        <span v-if="isMobile">{{ did | didfilter }}</span>
        <img
          class="copy mt-1"
          :class="{ click: isCopy }"
          src="@/assets/icons/icon_copy.png"
          @click="onCopyClick"
          alt=""
        />
      </div>
      <div>
        <div class="title mb-2">
          {{ $t("myInfoName") }}<span class="star">*</span>
        </div>
        <div class="box">
          <v-text-field
            ref="nameValue"
            v-model="nameValue"
            :rules="nameRules"
            label=""
            outlined
            maxlength="25"
          ></v-text-field>
        </div>
      </div>
       <div>
        <div class="title ">
          Social Connections
        </div>
        <div class="des mb-5">
          Help collectors verify your account by connecting Twitter
        </div>
      </div>
      <div class="d-flex justify-space-between mb-9" >
        <div class="twtter d-flex flex-row">
          <img class="img"  src="@/assets/icons/icon_twitter.png" alt="" />
          <div class="twtterDes">Your Twitter</div>
        </div>
        <div class="connect" :class="{twitterConnect:this.twitterVerify}">
          <div v-if="!this.twitterVerify" style="width:100%;height:100%">
               <button  class="connectText" @click="Connect" >Connect</button>
          </div>
          <div v-else style="width:100%;height:100%">
              <button  class="connectTwitter"   ><span>{{twitterName}}</span> </button>
          <img
          v-if="this.twitterVerify"
          class="clo"
          @click="disConnect"
          src="@/assets/icons/btn_close.png"
          alt=""
        />
        </div>
      </div>
       
      </div>
      
      <div>
        <div class="title mb-2">{{ $t("myInfoFormMobile") }}</div>
        <div class="box">
          <v-text-field v-model="mobileValue" label="" outlined></v-text-field>
        </div>
      </div>
      <div>
        <div class="title mb-2">
          {{ $t("myInfoFormEMail")
          }}<span>
            <!-- <img
              class="ml-1"
              width="18px "
              height="18px"
              v-if="bindEmail"
              src="@/assets/icons/icon_yan_yes.png"
              alt=""
            /> -->
           
          </span>
        </div>
        <div class="box">
          <v-text-field
            ref="emailValue"
            v-model="emailValue"
            :rules="[rules.email]"
            label=""
            outlined
          ></v-text-field>
        </div>
        <!-- <div class="box code" style="position: relative" v-if="!bindEmail">
          <v-text-field
            ref="verifycode"
            v-model="verifycode"
            label=""
            outlined
          ></v-text-field>
          <div
            class="verifycode"
            :class="{ verifycode_dis: sendcode_time > 0 }"
            @click="verifycodeClick"
          >
            {{ sendcode_time == 0 ? "Send" : sendcode_time }}
          </div>
        </div> -->
      </div>
      <div>
        <div class="title mb-2">{{ $t("myInfoFormLinks") }}</div>
        <div class="box InstagramValue">
          <v-text-field
            v-model="InstagramValue"
            :label="this.$t('myInfoFormLinksInstagram')"
            prepend-inner-icon="mdi-map-marker"
            outlined
          ></v-text-field>
        </div>
      
        <div class="box YoutubeValue">
          <v-text-field
            v-model="YoutubeValue"
            :label="this.$t('myInfoFormLinksYoutube')"
            prepend-inner-icon="mdi-map-marker"
            outlined
          ></v-text-field>
        </div>
        <div class="box SiteValue">
          <v-text-field
            v-model="SiteValue"
            :label="this.$t('myInfoFormLinksSite')"
            prepend-inner-icon="mdi-map-marker"
            outlined
          ></v-text-field>
        </div>
      </div>
      <div class="box">
        <div class="title mt-3 mb-2">
          {{ $t("popupAccountAppealDescription") }}
        </div>
        <div>
          <v-textarea
            outlined
            name="input-7-4"
            label=""
            counter="800"
            maxlength="800"
            :placeholder="this.$t('myInfoFormDescriptionPlaceholder')"
            v-model="descValue"
          >
          </v-textarea>
        </div>
      </div>
      <div class="inputimg d-flex flex-column flex-lg-row">
        <div
          class="uploadfile "
          v-for="(item, index) in uploadfile"
          :key="index"
        >
          <img class="img" :src="item"/>
          <img
            class="icon"
            src="@/assets/btn_close.png"
            @click="imgRemove(index)"
          />
        </div>
        <div class="uploadfile" v-if="uploadfile.length < 3">
          <FIleInput accept=".jpg,.png,.gif"
            class="FIleInput"
            @change="changeimg"
            :key="imgInputKey"
          >
            <template>
              <v-btn dark width="94px" height="24px" rounded class="imgbtn"
                >Choose File
              </v-btn>
              <div class="file-limit">JPG, PNG, GIF<br/> (Max 5MB)
              </div>
            </template>
          </FIleInput>
        </div>
      </div>
      <div class="footer box">
        <button class="sub" submit @click="subCommit">
          {{ $t("myInfoFormSave") }}
        </button>
      </div>
      <v-snackbar
        v-model="isShowSuccess"
        color="success"
        centered
        timeout="2000"
        style="opacity: 0.8"
        ><span style="color: white; font-size: 20px">{{
          successTitle
        }}</span></v-snackbar
      >
      <v-snackbar
        v-model="isShowFalse"
        color="error"
        centered
        timeout="2000"
        style="opacity: 0.8"
        ><span style="color: white; font-size: 20px">{{
          falseTitle
        }}</span></v-snackbar
      >
    </div>

    <PromptBox ref="promptBox"></PromptBox>

    <!-- <v-alert class="alert" type="warning" v-if="showAlert">
      {{uploadInfo}}
    </v-alert> -->
    <!--
	<div class="mask">
		<img src="../../assets/loading.gif" />
	</div> -->
    <uComponents ref="ucom"></uComponents>
  </div>


</template>

<script>
import FIleInput from "@/components/FIleInput.vue";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import PromptBox from "@/components/PromptBox.vue";
import logoimg from "@/assets/default.png";



const UPLOAD_KEY = "UPLOAD";
const SYNOPSIS_KEY = "SYNOPSIS";

export default {
  components: { FIleInput, PromptBox },
  data: function () {
    return {
      callback:'',
         showMask: false,
           showMask1: false,
      isCopy: false,
      did: "",
      nameValue: "",
      mobileValue: "",
      emailValue: "",
      verifycode: "",
      InstagramValue: "",
      YoutubeValue: "",
      SiteValue: "",
      descValue: "",
      bindEmail: false,
      nameRules: [(v) => !!v || "Username is required."],
      // emailRules: [(v) => !!v || "Email is required."],
      uploadFileUrl: undefined,
      uploadAvatar: undefined,
      homeBackground: "",
      profilePhoto: "",
      isShowSuccess: false,
      isShowFalse: false,
      successTitle: "",
      falseTitle: "",
      uploadInfo: "",
      logoimg,
      //上传图片
      uploadfile: [],
      imgUrl: [],
      imgInputKey: 0,

      rules: {
        email: (value) => {
          if (value) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$t("verifyEmail");
          }
        },
      },

      fileInputKey: 0,
      sendcode_time: 0,
        oauth_token:'',
        twitterName:'',
        twitterVerify:false,
        oauth_verifier:''
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    showAlert: function () {
      return !!this.uploadInfo;
    },
  },
  async mounted() {
     this.callback = window.location.href
      
    if (this.$store.state.did != null) {
      let param = {
        did: this.$store.state.did,
      };
      let res = await api.home.getMyInfo(param);

      if (res.data == null) {
        this.did = this.$store.state.did;
        this.nameValue = "";
        this.mobileValue = "";
        this.emailValue = "";
        this.InstagramValue = "";
        this.YoutubeValue = "";
        this.SiteValue = "";
        this.descValue = "";
        this.homeBackground = "";
        this.profilePhoto = "";
        this.bindEmail = "";

        // this.$store.commit("getInfo", null);
        //this.uploadFileUrl = logoimg
        this.uploadAvatar = logoimg;
      } else {
        this.twitterVerify = res.data.twitterVerify;
        this.twitterName = res.data.twitterName;
        this.did = res.data.did;
        this.nameValue = res.data.userName == null ? "" : decodeURIComponent(res.data.userName) ;
        this.mobileValue = res.data.mobile == null ? "" : res.data.mobile;
        this.emailValue = res.data.email == null ? "" : res.data.email;
        this.InstagramValue =
          res.data.instagram == null ? "" : decodeURIComponent(res.data.instagram);
		
		  if(res.data.youtube == null||res.data.youtube == null||res.data.youtube == ""){
		  			   this.YoutubeValue = "https://www.youtube.com/channel/";
		  }else{
		  			   this.YoutubeValue = decodeURIComponent(res.data.youtube) ;
		  }
       
        this.SiteValue = res.data.homepage == null ? "" : decodeURIComponent(res.data.homepage);
        this.descValue = res.data.synopsis == null ? "" : decodeURIComponent(res.data.synopsis);
        this.homeBackground =
          res.data.homeBackground == null ? "" : decodeURIComponent(res.data.homeBackground);
        this.profilePhoto =
          res.data.profilePhoto == null ? "" : res.data.profilePhoto;
        this.bindEmail = res.data.bindEmail;

        // this.$store.commit("getInfo", res.data);

        if (this.homeBackground) {
          this.uploadFileUrl = await getFileSrc(
            UPLOAD_KEY,
            this.homeBackground
          );
        } else {
          this.uploadFileUrl = logoimg;
        }
        if (this.profilePhoto) {
          this.uploadAvatar = await getFileSrc(UPLOAD_KEY, this.profilePhoto);
        } else {
          this.uploadAvatar = logoimg;
        }
        if(res.data.synopsisImage){
          let imgLists = (res.data.synopsisImage).split(",");
           imgLists.forEach(async (item)=>{
            if(item != ''){
                let dessrc = await getFileSrc(SYNOPSIS_KEY,item)
              this.uploadfile.push(dessrc)
              this.imgUrl.push(item)
            }
          })
          
        }
      }
    }
    this.oauth_token =  this.$route.query.oauth_token
      this.oauth_verifier = this.$route.query.oauth_verifier
      if(this.oauth_verifier){
       let result= await api.home.access_token(this.oauth_token, this.oauth_verifier,this.callback)
       let userName = JSON.stringify(result.data).split('&')[3].split('=')[1]
        this.twitterName =userName.slice(0,userName.length-1);
        this.twitterVerify = true
      }
   
  },
  filters: {
    didfilter: function (value) {
      if (value && value.length > 24) {
        return value.substr(0, 12) + "..." + value.substr(-12);
      } else {
        return "";
      }
    },
  },
  methods: {
  
     async Connect(){

       this.callback = window.location.href


       let token =  await api.home.getToken(this.callback);
      let toker_str = JSON.stringify(token.data).split('&')[0].split('=')[1]
       let twitterUrl = 'https://api.twitter.com/oauth/authorize?oauth_token=' + toker_str
       window.location.href = twitterUrl
     
  
 
      },
      disConnect(){
        this.twitterName = ''
        this.twitterVerify = false
      },
    async subCommit() {
      if (!(await this.verify())) {
        return;
      }
    
      
      this.mobileValue = this.nameValue == "null" ? "" : this.mobileValue;
      this.emailValue = this.emailValue == "null" ? "" : this.emailValue;
      // this.InstagramValue =
      //   this.InstagramValue == "null" ? "" : this.InstagramValue;
      // this.TwitterValue = this.TwitterValue == "null" ? "" : this.TwitterValue;
      // this.YoutubeValue = this.YoutubeValue == "null" ? "" : this.YoutubeValue;
      // this.SiteValue = this.SiteValue == "null" ? "" : this.SiteValue;
      // this.descValue = this.descValue == "null" ? "" : this.descValue;
      this.homeBackground =
        this.homeBackground == "null" ? "" : this.homeBackground;
      this.profilePhoto = this.profilePhoto == "null" ? "" : this.profilePhoto;
      this.bindEmail = this.bindEmail == "null" ? "" : this.bindEmail;
       // this.imgUrl = this.imgUrl == "" ? "[do-null]" : this.imgUrl;
     

    // let nameValue = encodeURIComponent(this.nameValue);
    let InstagramValue,SiteValue
    if(this.InstagramValue == null)
    {
      InstagramValue = this.InstagramValue
    }else{
      InstagramValue = encodeURIComponent((this.InstagramValue).replace(/(^\s*)|(\s*$)/g, ""))
    }
    if(this.SiteValue == null){
      SiteValue = this.SiteValue
    }else{
       SiteValue = encodeURIComponent((this.SiteValue).replace(/(^\s*)|(\s*$)/g, ""))
    }
	let synopsiimg="";
	if(this.imgUrl.length>0){
		synopsiimg=this.imgUrl.join(',');
	}
      let params = {
        did: this.$store.state.did,
        userName: encodeURIComponent(this.nameValue),
        mobile: this.mobileValue,
        email: this.emailValue,
        instagram: InstagramValue,
        youtube: encodeURIComponent((this.YoutubeValue).replace(/(^\s*)|(\s*$)/g, "")),
        homepage: SiteValue,
        synopsis: encodeURIComponent(this.descValue),
        homeBackground: this.homeBackground,
        profilePhoto: this.profilePhoto,
        synopsisImage:synopsiimg,
         twitterName:this.twitterName,
        twitter:this.twitterName ?'https://twitter.com/'+this.twitterName : '',
      };
      params.synopsis = (params.synopsis == null||params.synopsis == "") ? "[do-null]" : params.synopsis;
      params.instagram =
        params.instagram == null ? "[do-null]" : params.instagram;
      params.twitter = params.twitter == null ? "[do-null]" : params.twitter;
      params.youtube = params.youtube == null ? "[do-null]" : params.youtube;
      params.homepage = params.homepage == null ? "[do-null]" : params.homepage;
      let res = await api.home.editMyInfo(params);
      this.$toast("success", res.msg).then(() => {
          window.eventBus.$emit("RefreshAvatar");
        //this.$router.push({name:"PersonalPage"})
      });
      // this.$refs.promptBox.show(res.msg);
      // if(res.msg === 'Succeed'){
      //   this.isShowSuccess = true
      //   this.successTitle= res.msg
      // }else{
      //     this.isShowFalse =true
      //     this.falseTitle= res.msg
      // }
    },
    async verify() {
      let nameVer = this.$refs.nameValue.validate(true);
      let emailVer = true;
      if (this.emailValue != "") {
        emailVer = false;
        emailVer = this.$refs.emailValue.validate(true);
        !nameVer
          ? this.$refs.nameValue.focus()
          : !emailVer
          ? this.$refs.emailValue.focus()
          : "";
      }

      if (this.verifycode != "" && this.emailValue != "") {
        let res = await api.home.emailVerify({
          type: "BINDING",
          address: this.$store.state.did,
          code: this.verifycode,
          email: this.emailValue,
        });
        if (!res.success) {
          this.isShowFalse = true;
          this.falseTitle = res.msg;
          return false;
        }
      }

      return nameVer && emailVer;
    },
    async change(file) {
        this.showMask = true;
      // JPG,PNG,GIF
      let ft = file.name.substr(file.name.lastIndexOf(".")+1);
      if("JPG,PNG,GIF,JPEG".indexOf(ft.toUpperCase()) == -1) {
          this.$toast("error", this.$t("file_type_not_support"));
          this.fileInputKey++;
          return;
      }

      this.fileInputKey++;
      this.banHash = await api.image.uploadImage(file);
      if (!this.banHash.data.data) {
        // this.tipmsg= this.banHash.data.msg;
        this.$refs.promptBox.show(this.banHash.data.msg);
        return;
      }
      if (this.banHash) {
        this.homeBackground = this.banHash.data.data;
        this.uploadFileUrl = await getFileSrc(
          UPLOAD_KEY,
          this.banHash.data.data
        );
      }
        this.showMask = false
    },
    async avaChange(file) {
        this.showMask1 = true;
      // JPG,PNG,GIF
      let ft = file.name.substr(file.name.lastIndexOf(".")+1);
      if("JPG,PNG,GIF,JPEG".indexOf(ft.toUpperCase()) == -1) {
          this.$toast("error", this.$t("file_type_not_support"));
          this.fileInputKey++;
          return;
      }
      
      this.fileInputKey++;
      this.avaHash = await api.image.uploadImage(file);
      if (this.avaHash) {
        this.profilePhoto = this.avaHash.data.data;
        this.uploadAvatar = await getFileSrc(
          UPLOAD_KEY,
          this.avaHash.data.data
        );
      }
         this.showMask1 = false
    },
    async changeimg(file) {
      // JPG,PNG,GIF
      let ft = file.name.substr(file.name.lastIndexOf(".")+1);
      if("JPG,PNG,GIF,JPEG".indexOf(ft.toUpperCase()) == -1) {
          this.$toast("error", this.$t("file_type_not_support"));
          this.imgInputKey++;
          return;
      }
    
      if(file.size > 10242880) {
            this.$toast("error", this.$t("image_not_support"));
            this.imgInputKey++;
            return;
        }
        // if(this.img){
        //   let imgLists = (this.img).split(",");
        //    imgLists.forEach(async (item)=>{
        //     if(item != ''){
        //        this.imgUrl.push(item);
        //     }
        //   })
          
        // }
      
      let res = await api.image.uploadImage(file);
      // console.log(file.type)  //video/mp4
      this.imgInputKey++;
      // console.log(res)
      let url = await getFileSrc(UPLOAD_KEY, res.data.data);
      this.uploadfile.push(url);
      this.imgUrl.push(res.data.data);
      console.log("wxl 66666666")
      console.log((this.imgUrl).toString())
    },

    imgRemove(idx) {
      this.uploadfile.splice(idx, 1);
      this.imgUrl.splice(idx,1)
      // console.log(idx);
    },
    closeDialog() {
      this.uploadFileUrl = "";
      this.homeBackground = "";
    },
    onCopyClick() {
      var input = document.createElement("input");
      input.value = this.did;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.isCopy = true;
      setTimeout(() => (this.isCopy = false), 100);
      this.$toast("success", this.$t('copt_success'))
    },
    async verifycodeClick() {
      if (this.sendcode_time > 0) return;

      let res = await api.home.getEmailCode({
        type: "BINDING",
        to: this.emailValue,
      });
      if (!res.success) {
        this.isShowFalse = true;
        this.falseTitle = res.msg;
        return false;
      }

      let self = this;
      this.sendcode_time = 60;
      (function fun() {
        setTimeout(() => {
          self.sendcode_time--;
          if (self.sendcode_time > 0) {
            fun();
          }
        }, 1000);
      })();
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  margin: 0;
  padding: 0;

  .banner {
    height: 301px;
    background-color: #f8f6fd;
    position: relative;

    .clo {
      visibility: hidden;
      position: relative;
      left: 580px;
      top: 15px;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      z-index: 9999;
    }
    &:hover .clo {
      visibility: visible;
    }

    .bannerBtn {
      z-index: 99;
      position: absolute;
      left: 43%;
      top: 40%;
    }
 

    .sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
   margin-left: 175px;
  margin-top: 3px;
}


    .bannerurl {
      margin-top: -60px;
    }

    .edit_profile {
      margin: 0 auto;
      text-align: center;
      width: 186px;
      height: 40px;
      background-color: #270645;
      border-radius: 20px;
     font-family:Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .image {
    margin-top: -43px;

    .ava {
      margin-left: 42%;
      background-color: #ddd;
      border: solid 8px #ffffff;
    }

    .edit_img {
      .edit_image {
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        width: 140px;
        height: 40px;
        background-color: #270645;
        border-radius: 20px;
       font-family:Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0px;
        color: #ffffff;
        -webkit-user-select: none;
      }
         .sub-dis1::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
   margin-left: 4px;
  margin-top: 11px;
}
    }
  }

  .form {
    height: auto;
    margin: 0 384px;

    .copy_link {
      text-align: center;

      span {
        display: inline-block;
        font-family:Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .copy {
        display: inline-block;
        width: 13px;
        height: 15px;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .title {
     font-family:Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
     .twtter{
      margin: auto 0px;
      img{
        width: 25px;
        height: 25px;
      }
      .twtterDes{
        margin-left: 13px;
        font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      }
    }
    .twitterConnect{
      	border: solid 2px #e3e3e3 !important;
        background-color: #ffffff !important;
    }
     .connect{
      min-width: 100px;
      height: 41px;
      background-color: #270645;
      border-radius: 20px;
              position: relative;
    
      .connectText{
        width: 100%;
        height: 100%;
         font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        text-align: center;
        align-items: center;
  
      }
      .connectTwitter{
        width: 85%;
        height: 100%;
         font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        text-align: center;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;  
        span{
          	color: #1d42ff;
        } 

      }
      .clo {
    position: absolute;
    top: 11px;
    left: 5px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
    border-radius: 50% !important;
    z-index: 99;
  }
    

    }
    .des{
      font-family: Helvetica;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #766983;
    }

    .box {
      width: 450px;
    }
    .inputimg {
        width: 450px;
      .uploadfile {
        margin-bottom: 14px;
        width: 141px;
        height: 140px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 1px;
        border: solid 2px #e3e3e3;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 15px;
        object-fit:contain;
        cursor: pointer;

        .FIleInput {
          width: 100%;
          height: 100%;
          padding:30px 23px;

          .v-icon {
            width: 100%;
            height: 100%;
            padding: 25px;
          }
          .imgbtn{
            margin:5px auto;
            background-color: #270645;
	          border-radius: 12px;
           font-family:Helvetica;
            font-size: 9px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          right: 0px;
          background-color: #fff;
          z-index: 99;
          cursor: pointer;
        }

        .img {
          width: 100%;
          height: 100%;
          margin: 0;
          object-fit: contain;
        }
      }

      .file-limit {
        // width: 137px;
        height: 13px;
        font-family:Helvetica;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 14px;
        letter-spacing: 0px;
        color: #766983;
        margin-top:5px;
        text-align:center;
      }
    }
  }

  .footer {
    margin-bottom: 90px;

    .sub {
      margin: 14px auto;
      width: 450px;
      height: 50px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
     font-family:Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  &.mobile {
    // width:100%;
    .banner {
      width: 100%;
      height: 180px;
      position: relative;
      .clo {
        visibility: hidden;
        position: relative;
        left: 90px;
        top: 15px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        z-index: 9999;
      }
      &:hover .clo {
        visibility: visible;
      }
      // padding-top: 30px;
      .bannerBtn {
        position: absolute;
        left: 22%;
        top: 30%;
      }

      .bannerurl {
        margin-top: -60px;
      }

      .edit_profile {
        margin: 0 auto;
      }
    }

    .image {
      margin-top: -50px;
      margin-left: 45px;

      .ava {
        margin: 0;
      }

      .edit_img {
        // width: 100%;
        .edit_image {
          margin-left: 30px;
        }
      }
    }

    .form {
      // width: 100%;
      margin: 0;

      .copy_link {
        width: 100%;

        span {
          width: 100%;
          display: inline-block;
        }

        .copy {
          display: inline-block;
        }
      }

      .box {
        width: 100%;
      }
      .inputimg{
        width: 100%;
        .uploadfile{
          width:280px;
          height:280px;
          .FIleInput{
            text-align:center;
            margin-top:55%;
          }
        }

      }
    }

    .footer {
      .sub {
        width: 100%;
      }
    }
  }
}

.InstagramValue ::v-deep .v-icon::before {
  content: "";
  background-image: url(../../assets/icons/icon_Instagram.png);
  background-size: 100%;
  width: 22px;
  height: 22px;
}

.TwitterValue ::v-deep .v-icon::before {
  content: "";
  background-image: url(../../assets/icons/icon_twitter.png);
  background-size: 100%;
  width: 22px;
  height: 22px;
}

.YoutubeValue ::v-deep .v-icon::before {
  content: "";
  background-image: url(../../assets/icons/icon_youtube.png);
  background-size: 100%;
  width: 22px;
  height: 22px;
}

.SiteValue ::v-deep .v-icon::before {
  content: "";
  background-image: url(../../assets/icons/icon_site.png);
  background-size: 100%;
  width: 22px;
  height: 22px;
}
.alert {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.star {
  color: red;
  margin-left: 10px;
}
.contain ::v-deep.v-messages__message {
  color: red;
}

.verifycode {
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  width: 126px;
  height: 40px;
  background-color: #270645;
  border-radius: 20px;
 font-family:Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 40px;
  letter-spacing: 0px;
  color: #ffffff;
  -webkit-user-select: none;
  position: absolute;
  right: 8px;
  top: 8px;
}
.verifycode_dis {
  background-color: #766983 !important;
}
.code ::v-deep .v-text-field__details {
  display: none;
}
</style>
